import { ICargosApiService } from "modules/cargos/models/interface/ICargosApiService";
import { api } from "../Api";
import { IGetRolesResponse } from "modules/cargos/models/dtos/IGetRolesResponse";
import { IGetAllowedRolesResponse } from "modules/cargos/models/dtos/IGetAllowedRolesResponse";
import { IPostNewRoleRequest } from "modules/cargos/models/dtos/IPostNewRoleRequest";
import { IPutEditRoleRequest } from "modules/cargos/models/dtos/IPutEditRoleRequest";
import { IGetRegisteredRolesListParams } from "modules/cargos/models/dtos/getRolesList/IGetRegisteredRolesListParams";
import { IGetRegisteredRolesListResponse } from "modules/cargos/models/dtos/getRolesList/IGetRegisteredRolesListResponse";
import { IGetAllRolesResponse } from "modules/cargos/models/dtos/IGetAllRolesResponse";
import { IGetRolesByFunctionResponse } from "modules/cargos/models/dtos/IGetRolesByFunctionResponse";
import { SystemIdentifierEnum } from "modules/gestaoVendas/domain/enum/GetSegmentosEnum";

const CargosApi = (): ICargosApiService => {
  const getRoles = async (systemIdentifier: SystemIdentifierEnum): Promise<IGetRolesResponse[]> => {
    const response = await api.get(`/Acesso/BuscarPapeisNovoPortalv2/${systemIdentifier ?? ""}`);
    return response.data;
  };

  const getAllowedRoles = async (id: string): Promise<IGetAllowedRolesResponse> => {
    const response = await api.get(`/Acesso/BuscarAcessosUsuarioLocal/${id}`);
    return response.data;
  };

  const getRegisteredRolesList = async (params?: IGetRegisteredRolesListParams): Promise<IGetRegisteredRolesListResponse> => {
    const response = await api.get("/Acesso/Cargo", { params: params });
    return response.data;
  };

  const postNewRole = async (request: IPostNewRoleRequest): Promise<any> => {
    const response = await api.post("/acesso/cargo/", request, { params: { disableError: true } });
    return response.data;
  };

  const putEditRole = async (request: IPutEditRoleRequest): Promise<any> => {
    const response = await api.put("/acesso/cargo/", request);
    return response.data;
  };

  const deleteRole = async (profileId: string): Promise<any> => {
    const response = await api.delete(`/Acesso/Cargo/${profileId}`);
    return response.data;
  };

  const getAllRoles = async (ownerId: string, page?: number, pageSize?: number): Promise<IGetAllRolesResponse[]> => {
    const response = await api.get("/Acesso/Cargo/GetAllByOwner", {
      params: { ownerId: ownerId, page: page, pageSize: pageSize }
    });
    return response.data;
  };

  const getRolesByFunction = async (ownerId: string): Promise<IGetRolesByFunctionResponse[]> => {
    const response = await api.get("/Acesso/BuscarPapeisPorCargo", { params: { ownerId } });
    return response.data.map((x: any) => ({ id: x.Id, name: x.Name }));
  };

  return {
    getRoles,
    getAllowedRoles,
    getRegisteredRolesList,
    postNewRole,
    putEditRole,
    deleteRole,
    getAllRoles,
    getRolesByFunction,
  };
};

export default CargosApi;


import { IResponseConfigLocal } from "modules/gestaoVendas/domain/dtos/IGetSegmentos";
import { IGetSegmentosService } from "../interfaces/IGestaoVendasService";

const GetLocalConfigurationUseCase = async (
  accountService: IGetSegmentosService,
  id: string,
): Promise<IResponseConfigLocal> => {
  return await accountService.getSegmentos(id);
};

export default GetLocalConfigurationUseCase;

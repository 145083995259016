import {
  IBodyMesas,
  IBodyOperacoes,
  IBodySegmentos,
  IGetConfigDataResponse,
  IPostConfigDataRequest,
  IReponseMesas,
  IReponseOperacoes,
  IReponseOperadores,
  OperatiomMethodTypes,
} from "modules/gestaoVendas/application/interfaces/IGestaoVendasService";
import { IGestaoVendasApi } from "modules/gestaoVendas/presentation/pages/interfaces/IGestaoVendasApi";
import { api } from "../Api";
import { deviceConfigApi } from "../equipamento/DeviceConfigApi";
import { IResponseConfigLocal } from "modules/gestaoVendas/domain/dtos/IGetSegmentos";

export const GestaoVendasApi = (): IGestaoVendasApi => {
  const updateSegmentos = async (
    id: string,
    body: IBodySegmentos
  ): Promise<void> => {
    const result = await api.put(
      `/Proprietario/atualizarsegmentos?localClienteId=${id}`,
      body
    );
    return result.data;
  };

  const postMesas = async (body: IBodyMesas): Promise<void> => {
    const result = await api.post(`/comandaseletronicas/CriarEmMassa`, body);
    return result.data;
  };

  const getSegmentos = async (id: string): Promise<IResponseConfigLocal> => {
    const result = await api.get(
      `/Proprietario/BuscarConfiguracoesDoLocal/${id}`
    );
    return result.data;
  };

  const getMesas = async (id: string): Promise<IReponseMesas[]> => {
    const result = await api.get(`/comandaseletronicas/?localClienteId=${id}`);
    return result.data;
  };

  const getOperacao = async (id: string): Promise<IReponseOperacoes> => {
    const result = await api.get(`Proprietario/preconfiguracao-modooperacao?LocalClienteId=${id}`);
    return result.data

      ;
  };

  const postDefaultProfiles = async (id: string, methods: OperatiomMethodTypes[]): Promise<void> => {
    const result = await deviceConfigApi.post(`locals/${id}/terminal/createDefaultProfiles`,
      {
        localId: id,
        methods: methods
      }
    );
    return result.data;
  };

  const postOperacao = async (id: string, body: IBodyOperacoes): Promise<void> => {
    const result = await api.post(`Proprietario/preconfiguracao-modooperacao?LocalClienteId=${id}`, body);
    return result.data;
  };

  const getOperador = async (id: string,): Promise<IReponseOperadores[]> => {
    const result = await api.get(`Proprietario/BuscarOperadoresDoLocal/${id}`);
    return result.data;
  };

  const getConfigData = async (id: string): Promise<IGetConfigDataResponse> => {
    const result = await api.get(`/app/Configuration/${id}`, { params: { disableError: true } });
    return result.data;
  };

  const postConfigData = async (request: IPostConfigDataRequest): Promise<void> => {
    const result = await api.post(`/app/Configuration`, request);
    return result.data;
  };

  const closeLocal = async (localId: string, enabled: boolean): Promise<void> => {
    const result = await api.put(`/app/Configuration/${localId}/TemporarilyClosed`, { enabled });
    return result.data;
  };

  return {
    updateSegmentos,
    getSegmentos,
    getMesas,
    postMesas,
    getOperacao,
    postOperacao,
    getOperador,
    getConfigData,
    postConfigData,
    closeLocal,
    postDefaultProfiles
  };
};

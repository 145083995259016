import { create } from "zustand";
import { ILocal } from "modules/local/domain/models/ILocal";
import { IResponseConfigLocal } from "modules/gestaoVendas/domain/dtos/IGetSegmentos";
import UpdateCurrentLocalUseCase from "modules/local/application/useCases/UpdateCurrentLocalUseCase";
import GetLocalConfigurationUseCase from "modules/gestaoVendas/application/useCases/GetSegmentosUseCase";
import { LocalRepository } from "services/repository/local/LocalRepository";
import { GestaoVendasApi } from "services/api/gestaoVendas/gestaoVendasApi";
import SaveLocalsUseCase from "modules/local/application/useCases/SaveLocalsUseCase";
export interface ILocalStore {
    updateChangeCurrentLocal: (newCurrentLocals: ILocal) => ILocal,
    getLocalConfiguration: (localId: string) => Promise<IResponseConfigLocal>,
    setLocals: (locals: ILocal[]) => void,
    currentLocal?: ILocal,
    locals?: ILocal[],
    localConfiguration?: IResponseConfigLocal,
}

export const UseLocalStore = create<ILocalStore>((set,get) => ({
  updateChangeCurrentLocal: ( newCurrentLocals: ILocal) => {
        UpdateCurrentLocalUseCase(
          newCurrentLocals,
          LocalRepository
        );
        get().getLocalConfiguration(newCurrentLocals.id ?? undefined);
        set({ currentLocal: newCurrentLocals });
        return newCurrentLocals;
  },
  getLocalConfiguration: async (localId: string) => {
      var gestaoService = GestaoVendasApi();
      const response = await GetLocalConfigurationUseCase(gestaoService, localId);
      set({ localConfiguration: response });
      return response;
  },
  setLocals: (locals: ILocal[]) => {
    const savedLocals = SaveLocalsUseCase(locals, LocalRepository);
    if (savedLocals.currentLocal?.id) {
      get().getLocalConfiguration(savedLocals.currentLocal.id);
    }
    set({ locals: locals, currentLocal: savedLocals.currentLocal?? undefined});
  },
  currentLocal: LocalRepository().getCurrentLocal()?? undefined,
  locals: LocalRepository().getLocals()?? undefined,
  localConfiguration: undefined,
}));

export enum TipoBaixaEstoqueEnum {
  NaoRealiza = 0,
  AoVender = 1,
  AoValidarTicket = 2
}

export enum TempoLiquidacaoEnum {
  Indefinido = 0,
  D3_C30 = 1,
  D1_C1 = 2,
  D3_C3 = 3,
  D1_C30 = 4,
  D1_C3 = 5
}

export enum ModoUtilizacaoCashbackEnum {
  Nenhum = 0,
  MinimoValorDaCompra = 1,
  ValorConfigurado = 2,
}

export enum ModoAtualizacaoCodigoCashlessEnum {
  NuncaAtualizar = 0,
  ACadaNovoCaixa = 1,
}

export enum TipoRelatorioAoSairEnum {
  Padrao = 0,
  Resumido = 1
}

export enum SystemIdentifierEnum {
  General = 0,
  Saas = 1,
}
